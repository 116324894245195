import React from "react"
// import { Link } from "react-router-dom"
import { MenuItems } from './MenuItems'
import './Navbar.css'
import img_logo from '../../assets/images/logo_nyuciin_horizontal.png'
import img_logo_white from '../../assets/images/logo_nyuciin_horizontal_white.png'

export default class Navbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            clicked:false, 
            activeMenu:"Kenapa", 
            nav_background_class:"",
            nav_logo:img_logo
        }
    }
    componentDidMount() { window.addEventListener("scroll", this.onWindowScrolls) }

    // ====================================================================================
    // ========== ACTIONS SECTIONS ========================================================
    // ====================================================================================
    onWindowScrolls=()=>{
        if (window.pageYOffset > 50) {
            if(!this.state.nav_background_class){
                this.setState({ 
                    nav_background_class:"nav-background",
                    nav_logo:img_logo_white
                }) 
            }
        }else{
            if(this.state.nav_background_class){
                this.setState({ 
                    nav_background_class:"",
                    nav_logo:img_logo
                })
            }
        }
    
    }
    onClickListeners = () => {
        this.setState({clicked:!this.state.clicked})
    }
    onClickMenuItemListeners = (title) => {
        this.setState({activeMenu:title, clicked:!this.state.clicked})
    }

    // ====================================================================================
    // ========== RENDER SECTIONS =========================================================
    // ====================================================================================
    render() {
        return(
            <div className={`NavbarItems ${this.state.nav_background_class}`}>
                <img className="navbar-logo" src={this.state.nav_logo} alt="Nyuciin.id"/>
                <div className="menu-icon" onClick={this.onClickListeners}>
                    <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        let menuActive = 'menu-inactive'
                        if(this.state.activeMenu === item.title) {
                            menuActive = "menu-active"
                        }
                        return(
                            <li key={index}>
                                { item.title === "Kebijakan Privasi" ?
                                    // <Link to="/privacy" className={`${item.cName} ${menuActive}`}>{item.title}</Link>
                                    <a className={`${item.cName} ${menuActive}`} href="https://nyuciin.id/privacy">
                                        {item.title}
                                    </a>
                                    :
                                    <a className={`${item.cName} ${menuActive}`} href={item.url}
                                        onClick={() => this.onClickMenuItemListeners(item.title)}
                                        >
                                        {item.title}
                                    </a>
                                }
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}