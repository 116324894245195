import React from "react"
import './Service.css'
import img_intro_manage from '../assets/images/img_intro_manage.png'
import img_intro_payment from '../assets/images/img_intro_payment.png'
import img_intro_customer from '../assets/images/img_intro_customer.png'

export default class Service extends React.Component {
    render() {
        return(
            <div id="services" className="MainContentsService">
                <h1 className="white">Layanan &amp; Fitur</h1>
                <div className="main-subtitle white">
                    Apa sih yang membuat Nyuciin.id istimewa.
                </div>
                <div className="main-content-service">
                    <div className="main-content-service-grid">
                        <img className="image-service" src={img_intro_manage} alt="Nyuciin.id - Pantau Cucian Gampang"/>
                        <h3 className="white">Pantau Cucian Gampang</h3>
                        <div className="main-content-service-grid-subtitle white">
                            Cucian aman, karena Anda dapat memantau langsung proses cucian Anda.
                        </div>
                    </div>
                    <div className="main-content-service-grid">
                        <img className="image-service" src={img_intro_payment} alt="Nyuciin.id - Bisa Bayar Dimana Saja"/>
                        <h3 className="white">Bisa Bayar Dimana Saja</h3>
                        <div className="main-content-service-grid-subtitle white">
                            Pelanggan anda dapat membayar melalui metode pembayaran daring dengan mudah, aman &amp; nyaman.
                        </div>
                    </div>
                    <div className="main-content-service-grid">
                        <img className="image-service" src={img_intro_customer} alt="Nyuciin.id - Selalu Ada Promo"/>
                        <h3 className="white">Selalu Ada Promo</h3>
                        <div className="main-content-service-grid-subtitle white">
                            Anda bisa memberikan promo yang menarik untuk pelanggan setia Anda.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}