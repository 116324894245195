import React from "react"
import './Download.css'
import download_img from '../assets/images/download_img.png'
import img_download_play_store from '../assets/images/download_play_store.png'

export default class Download extends React.Component {
    render() {
        return(
            <div id="prices" className="MainContentsDownload">
                <div className="main-content-download">
                    <img className="image-download" src={download_img} alt="Nyuciin.id - Download"/>
                    <div className="desc-download">
                        <h1 className="white align-left">Opps… Fiturnya Kurang…</h1>
                        <div className="main-subtitle white align-left">
                            Penasaran kan? Makanya ayo buruan download Aplikasi nya di Google Play Store. langsung daftar aja gampang kok bisa pakai Akun Google Anda. Mumpung masih GRATIS LOH…
                        </div>

                        <a className="link-download-play-store" href="https://play.google.com/store/apps/details?id=com.nyuciin_merchant" target="_blank" rel="noreferrer">
                            <img alt="Google Play Store" src={img_download_play_store} className="img-download-play-store"/>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}