import React from 'react';
import '../App.css';
import Navbar from '../components/Navbar/Navbar';
import MyHeader from '../components/MyHeader/MyHeader';
import Why from '../contents/Why';
import Service from '../contents/Service';
import Download from '../contents/Download';
import Footer from '../contents/Footer';

export default class Landing extends React.Component {
    render() {
        return (
            <div className="App">
                <Navbar />
                <MyHeader />
                <div className="MainContents">
                    <Why />
                    <Service />
                    <Download />
                    <Footer />
                </div>
            </div>
        )
    }
}