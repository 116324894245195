import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import Landing from "./pages/Landing"
import Privacy from "./pages/Privacy"

export default function App() {
  return (
    <Router>
      <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
        </Switch>
    </Router>
  )
}