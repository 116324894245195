import React from "react"
import './MyHeader.css'
import img_header_rounded from '../../assets/images/header_rounded.png'
import img_header_rounded_item from '../../assets/images/header_rounded_item.png'
import header_rounded_item_responsive from '../../assets/images/header_rounded_item_responsive.png'
import img_download_play_store from '../../assets/images/download_play_store.png'

export default class MyHeader extends React.Component {
    render() {
        return(
            <div id="why" className="HeaderContainers">
                <div className="header-top-image-content">
                    <img alt="Nyuciin.id" src={header_rounded_item_responsive} className="header-top-image"/>
                </div>
                <div className="header-left">
                    <div className="header-left-container">
                        <h1 className="header-left-title">
                            Laundry on Demand
                        </h1>
                        <div className="header-left-subtitle">
                            Mau kelola cucian Anda menjadi lebih mudah, biarkan Nyuciin.id menyederhanakan semua proses ini untuk Anda. Download aja dulu.
                        </div>
                        <a className="link-download-play-store" href="https://play.google.com/store/apps/details?id=com.nyuciin_merchant" target="_blank" rel="noreferrer">
                            <img alt="Google Play Store" src={img_download_play_store} className="img-download-play-store"/>
                        </a>
                    </div>
                </div>
                <div className={'header-right'}>
                    <div className="header-right-container">
                        <img alt="Nyuciin.id" src={img_header_rounded} className="header-right-img-container"/>
                    </div>
                    <img alt="Nyuciin.id" src={img_header_rounded_item} className="header-right-img-item"/>
                </div>
            </div>
        )
    }
}