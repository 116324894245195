const MenuItems = [
    {
        title:"Kenapa",
        url:"#why",
        cName:"nav-links"
    },
    {
        title:"Layanan & Fitur",
        url:"#services",
        cName:"nav-links"
    },
    {
        title:"Harga",
        url:"#prices",
        cName:"nav-links"
    },
    {
        title:"Kebijakan Privasi",
        url:"",
        cName:"nav-links"
    },
    {
        title:"Login",
        url:"https://merchant.nyuciin.id",
        cName:"nav-links"
    },
    // {
    //     title:"Login Merchant",
    //     url:"#",
    //     cName:"nav-links-mobile"
    // }
]

export { MenuItems }