import React from "react"
import './Why.css'
import img_why from '../assets/images/why_img_1.png'
import why_img_content_1 from '../assets/images/why_img_content_1.png'
import why_img_content_2 from '../assets/images/why_img_content_2.png'
import why_img_content_3 from '../assets/images/why_img_content_3.png'
import why_img_content_4 from '../assets/images/why_img_content_4.png'

export default class Why extends React.Component {
    render() {
        return(
            <div className="MainContentsWhy">
                <div className="main-content-why-top">
                    <img className="image-why" src={img_why} alt="Why Nyuciin.id"/>
                    <div className="desc-why">
                        <h1 className="align-left">Kenapa Harus Nyuciin.id</h1>
                        <div className="main-subtitle align-left">
                            Nyuciin.id membuat semua jadi mudah dan gampang.
                        </div>
                    </div>
                </div>
                <div className="main-content-why-bottom">
                    <div className="main-content-why-bottom-grid">
                        <img className="image-why-content" src={why_img_content_1} alt="Why Nyuciin.id - Kelola Cucian Mudah"/>
                        <h3 className="align-left white">Kelola Cucian Mudah</h3>
                        <div className="main-content-why-bottom-grid-subtitle white">
                            Fitur Nyuciin.id yang kaya membantu kelola bisnis jadi mudah.
                        </div>
                    </div>
                    <div className="main-content-why-bottom-grid">
                        <img className="image-why-content" src={why_img_content_2} alt="Why Nyuciin.id - Multi Store (Outlet)"/>
                        <h3 className="align-left white">Multi Store (Outlet)</h3>
                        <div className="main-content-why-bottom-grid-subtitle white">
                            Tidak perlu kawatir jika Anda memiliki lebih dari satu outlet (toko) dan memiliki banyak karyawan. Nyuciin.id bisa melakukan itu.
                        </div>
                    </div>
                    <div className={"main-content-why-bottom-grid"}>
                        <img className="image-why-content" src={why_img_content_3} alt="Why Nyuciin.id - Pembayaran Mudah"/>
                        <h3 className="align-left white">Pembayaran Mudah</h3>
                        <div className="main-content-why-bottom-grid-subtitle white">
                            Nyuciin.id menyediakan berbagai opsi metode pembayaran yang mudah (dan dapat Anda disesuikan) untuk pelanggan Anda.
                        </div>
                    </div>
                    <div className="main-content-why-bottom-grid">
                        <img className="image-why-content" src={why_img_content_4} alt="Why Nyuciin.id - Pelangggan-pun Senang"/>
                        <h3 className="align-left white">Pelangggan-pun Senang</h3>
                        <div className="main-content-why-bottom-grid-subtitle white">
                            Status cucian dengan mudah dipantau oleh pelanggan Anda. Serta pelanggan Anda bisa bayar kapanpun dimanapun.
                        </div>
                    </div>
                </div>
                <div className="main-content-why-bottom-responsive">
                    <div className="main-content-why-bottom-grid">
                        <img className="image-why-content" src={why_img_content_1} alt="Why Nyuciin.id - Kelola Cucian Mudah"/>
                        <h3 className="align-left">Kelola Cucian Mudah</h3>
                        <div className="main-content-why-bottom-grid-subtitle align-left">
                            Fitur Nyuciin.id yang kaya membantu kelola bisnis jadi mudah.
                        </div>
                    </div>
                    <div className="main-content-why-bottom-grid">
                        <img className="image-why-content" src={why_img_content_2} alt="Why Nyuciin.id - Multi Store (Outlet)"/>
                        <h3 className="align-left">Multi Store (Outlet)</h3>
                        <div className="main-content-why-bottom-grid-subtitle align-left">
                            Tidak perlu kawatir jika Anda memiliki lebih dari satu outlet (toko) dan memiliki banyak karyawan. Nyuciin.id bisa melakukan itu.
                        </div>
                    </div>
                    <div className={"main-content-why-bottom-grid"}>
                        <img className="image-why-content" src={why_img_content_3} alt="Why Nyuciin.id - Pembayaran Mudah"/>
                        <h3 className="align-left">Pembayaran Mudah</h3>
                        <div className="main-content-why-bottom-grid-subtitle align-left">
                            Nyuciin.id menyediakan berbagai opsi metode pembayaran yang mudah (dan dapat Anda disesuikan) untuk pelanggan Anda.
                        </div>
                    </div>
                    <div className="main-content-why-bottom-grid">
                        <img className="image-why-content" src={why_img_content_4} alt="Why Nyuciin.id - Pelangggan-pun Senang"/>
                        <h3 className="align-left">Pelangggan-pun Senang</h3>
                        <div className="main-content-why-bottom-grid-subtitle align-left">
                            Status cucian dengan mudah dipantau oleh pelanggan Anda. Serta pelanggan Anda bisa bayar kapanpun dimanapun.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}