import React from 'react'
import './Privacy.css'
import img_logo from '../assets/images/logo_nyuciin_horizontal.png'
import img_logo_white from '../assets/images/logo_nyuciin_horizontal_white.png'
import logo_nyuciin from '../assets/images/logo_nyuciin.png'

export default class Privacy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            second_toolbar_active:"",
            second_toolbar_title_scrolled:"",
            icon_back_scrolled:"",
            nav_logo:img_logo
        }
    }
    componentDidMount() { window.addEventListener("scroll", this.onWindowScrolls) }

    // ====================================================================================
    // ========== ACTIONS SECTIONS ========================================================
    // ====================================================================================
    onWindowScrolls=()=>{
        if (window.pageYOffset > 0) {
            if(!this.state.second_toolbar_active){
                this.setState({ 
                    second_toolbar_active:"second-toolbar-active",
                    second_toolbar_title_scrolled:"second-toolbar-title-scrolled",
                    icon_back_scrolled:"icon-back-scrolled",
                    nav_logo:img_logo_white
                }) 
            }
        }else{
            if(this.state.second_toolbar_active){
                this.setState({ 
                    second_toolbar_active:"",
                    second_toolbar_title_scrolled:"",
                    icon_back_scrolled:"",
                    nav_logo:img_logo
                })
            }
        }
    
    }

    // ====================================================================================
    // ========== RENDER SECTIONS =========================================================
    // ====================================================================================
    render() {
        return (
            <div className="MainContentsPrivacy">
                <div className={`second-toolbar ${this.state.second_toolbar_active}`}>
                    <a href="https://nyuciin.id" className="second-toolbar-button">
                        <i className={`fas fa-arrow-left icon-back ${this.state.icon_back_scrolled}`}></i>
                    </a>
                    <img className="navbar-logo show-logo" src={this.state.nav_logo} alt="Nyuciin.id"/>
                    <div className={`second-toolbar-title ${this.state.second_toolbar_title_scrolled}`}>
                        Kebijakan Privasi
                    </div>
                </div>
                <div className="second-content">
                    <h1>Kebijakan Privasi Nyuciin.id</h1>
                    <div className="second-content-desc">
                        <p className="p-desc">Kebijakan privasi ini mendeskripsikan kebijakan dan prosedur kami dalam mengkoleksi, menggunakan dan mengungkap informasi Anda saat Anda menggunakan layanan kami (seperti yang akan dijelaskan dibawah) dan menginformasikan kepada Anda mengenai hak atas privasi Anda dan bagaimana hukum yang berlaku dapat melindungi Anda.</p>
                        <br />
                        <p className="p-desc">Dengan menggunakan website ini, Anda mengakui bahwa Anda telah membaca dan mengerti atas Kebijakan Privasi dari <b>Nyuciin.id</b> dan setuju atas pengambilan, penggunaan dan pengungkapan data pribadi yang dikirimkan oleh Anda kepada kami, seperti yang dijelaskan pada Kebijakan Privasi <b>Nyuciin.id</b>. Apabila Anda tidak menyetujui, mohon untuk tidak mengirimkan data pribadi. Mohon untuk tidak mengirimkan data pribadi Anda maupun individu lain kepada kami melalui website ini kecuali Anda telah mengungkapkan persetujuan untuk melakukan hal tersebut. Kami berhak berasumsi bahwa Anda telah memperoleh persetujuan tersebut jika Anda mengirimkan data pribadi Anda atau data orang lain kepada kami melalui website ini.</p>

                        <h2 className="h2-subtitle">Koleksi dan Penggunaan Data Pribadi Anda</h2>
                        <h3 className="h3-subtitle">Koleksi Data Pribadi</h3>
                        <p className="p-desc">Saat menggunakan Layanan, Kami akan meminta Anda untuk memberikan Kami beberapa informasi pribadi yang dapat digunakan untuk menghubungi atau mengidentifikasi identitas Anda. Data pribadi yang Anda berikan meliputi: Email, Nama, Nomor Telpon dan Penggunaan Data.</p>

                        <br />
                        <h3 className="h3-subtitle">Penggunaan Data Pribadi</h3>
                        <p className="p-desc">Nyuciin.id menggunakan data pribadi Anda dengan tujuan:</p>
                        <ul className="p-desc-list">
                            <li><p className="p-desc">Untuk menyediakan dan mengelola layanan kami, termasuk untuk memonitor penggunaan Layanan kami.</p></li>
                            <li><p className="p-desc">Untuk menghubungi Anda, yakni untuk menghubungi Anda melalui email, panggilan telepon, SMS, atau bentuk komunikasi elektronik lainnya termasuk pemberitahuan dari aplikasi terkait konten baru atau informasi lainnya berkaitan dengan fungsionalitas, produk atau layanan, perubahan kebijakan keamanan, dan layanan pelanggan.</p></li>
                            <li><p className="p-desc">Untuk menyediakan konten yang relevan untuk Anda seperti berita, penawaran spesial dan informasi umum layanan serta agenda.</p></li>
                        </ul>

                        <br />
                        <h3 className="h3-subtitle">Penyimpanan Data Pribadi</h3>
                        <ul className="p-desc-list">
                            <li><p className="p-desc">Nyuciin.id akan menyimpan Data Pribadi Anda selama yang dibutuhkan untuk tujuan yang diatur dalam Kebijakan Privasi ini. Kami akan menyimpan dan menggunakan Data Pribadi Anda hingga waktu yang tidak ditentukan untuk memenuhi kewajiban legal (sebagai contoh, saat kami diminta menyimpan data Anda untuk mematuhi hukum yang berlaku), menyelesaikan perselisihan, dan menegakkan perjanjian dan kebijakan hukum.</p></li>
                            <li><p className="p-desc">Kami juga akan menyimpan Data Penggunaan untuk keperluan analisa internal. Penggunaan Data disimpan dalam jangka waktu pendek, kecuali apabila data ini digunakan untuk memperkuat keamanan atau untuk meningkatkan fungsionalitas dari Layanan Kami.</p></li>
                        </ul>

                        <br />
                        <h3 className="h3-subtitle">Pembagian Data Pribadi</h3>
                        <p className="p-desc">Nyuciin.id hanya membagikan data pribadi kepada mitra yang terkait dengan layanan yang Kami sediakan. Misalnya layanan pembayaran daring dengan menggunakan teknologi pihak ketiga <a href="https://midtrans.com" target="_blank"  rel="noreferrer">Midtrans Payment Gateway</a>.</p>

                        <br />
                        <h3 className="h3-subtitle">Keamanan Data Pribadi</h3>
                        <p className="p-desc">Keamanan Data Pribadi Anda merupakan hal yang penting bagi Kami, tetapi harap diingat bahwa tidak ada metode transmisi via internet, atau metode penyimpanan elektronik yang 100% aman. Saat kami berusaha menjaga keamanan Data Pribadi Anda, kami tidak bisa menjamin keamanan seutuhnya.</p>

                        <h2 className="h2-subtitle">Privasi Anak</h2>
                        <p className="p-desc">Layanan ini tidak diperuntukkan bagi anak dibawah usia 13 tahun. Kami tidak dapat mengidentifikasi apabila pengguna adalah anak berusia dibawah 13 tahun. Apabila Anda adalah orangtua atau wali, dan Anda menyadari bahwa anak Anda telah mengirimkan Data Pribadi kepada kami, mohon untuk menghubungi Kami. Apabila Kami menyadari bahwa data yang kami koleksi berasal dari anak berusia dibawah 13 tahun tanpa izin dari orangtua, Kami akan membuang data tersebut dari server kami. Apabila Kami membutuhkan persetujuan sebagai dasar yang sah untuk memproses informasi Anda, dan negara Anda meminta persetujuan dari orangtua, Kami dapat meminta persetujuan dari orangtua Anda sebelum kami mengkoleksi dan menggunakan informasi tersebut.</p>

                        <h2 className="h2-subtitle">Perubahan Kebijakan Privasi</h2>
                        <p className="p-desc">Kami dapat memperbaharui Kebijakan Privasi ini dari waktu ke waktu. Kami akan memberitahukan kepada Anda atas berbagai perubahan dengan menyimpan Kebijakan Privasi baru di halaman ini. Kami akan memberitahu Anda melalui email dan/atau melalui jalur pemberitahuan lain di Layanan Kami, sebelum perubahannya berlaku dan memperbaharui tannggalnya di bagian atas Kebijakan Privasi. Anda disarankan untuk membaca Kebijakan Privasi secara berkala untuk melihat apabila terdapat perubahan. Perubahan tersebut berlaku efektif sejak disimpan di dalam halaman ini.</p>
                    </div>

                    <div className="main-content-footer-end">
                        <img className="image-footer-end" src={logo_nyuciin} alt="Nyuciin.id"/>
                        <div className="desc-footer-end">
                            Copyright by Nyuciin.id — Powered by Kode Kreativ @2021
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}