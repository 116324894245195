import React from "react"
import './Footer.css'
import footer_img_knop_horizontal from '../assets/images/footer_img_knop_horizontal.png'
import footer_img_partner_ngumbahke from '../assets/images/footer_img_partner_ngumbahke.png'
import logo_nyuciin from '../assets/images/logo_nyuciin.png'

export default class Footer extends React.Component {
    render() {
        return(
            <div className="MainContentsFooter">
                <img className="image-footer-knop" src={footer_img_knop_horizontal} alt="Nyuciin.id"/>
                <h1 className="">Partner &amp; Pelanggan Kami</h1>
                <div className="main-subtitle">
                    Mereka yang membuat Nyuciin.id menjadi sangat istimewa.
                </div>
                <div className="main-content-footer-partner">
                    <img className="image-footer-partner" src={footer_img_partner_ngumbahke} alt="Nyuciin.id"/>
                </div>
                <div className="main-content-footer-end">
                    <img className="image-footer-end" src={logo_nyuciin} alt="Nyuciin.id"/>
                    <div className="desc-footer-end">
                        Copyright by Nyuciin.id — Powered by Kode Kreativ @2021
                    </div>
                </div>
            </div>
        )
    }
}